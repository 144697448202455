<template>
  <v-card
    elevation="0"
    :class="
      mdAndUp
        ? 'overflow-auto principles_card cardwhitePValues mt-7 '
        : 'mt-1 mb-10 text-center'
    "
    :height="principles_card_height"
    width="100%"
  >
    <v-row dense class="header-row">
      <v-col cols="3" class="d-flex align-center justify-center borderblackeEV">
        <h5 class="text-uppercase">Valores</h5>
      </v-col>
      <v-col cols="6" class="d-flex align-center justify-center borderblackeEV">
        <h5 class="text-uppercase">Comportamientos</h5>
      </v-col>
      <v-col cols="3" class="d-flex align-center justify-center borderblackeEV">
        <h5 class="text-uppercase">Probabilidad</h5>
      </v-col>
      <!--   <v-col cols="3" class="d-flex align-center justify-center borderblackeEV">
        <h5 class="text-uppercase">Cálculo</h5>
      </v-col> -->
    </v-row>

    <!--   <v-row dense class="data-row">
      <v-col
        cols="12"
        class="d-flex align-center justify-center borderblackeEV"
      >
        <p class="descriptionPredValues">
          Token del Proyecto:
          {{ user_info?.tokens?.tokenPE || "No disponible" }}
        </p>
      </v-col>
    </v-row> -->

    <v-row dense class="data-row" style="height: calc(100% - 50px)">
      <v-col cols="12" class="d-flex flex-column">
        <div
          v-for="item in processedParamData"
          :key="item.value"
          class="d-flex justify-space-around align-center data-entry my-1"
        >
          <v-col cols="2" class="d-flex align-center justify-center">
            <p class="descriptionPredValues">{{ item.value }}</p>
          </v-col>
          <v-col cols="5" class="d-flex align-center justify-center pa-0">
            <p class="descriptionPredValues text-wrap">{{ item.behaviour }}</p>
          </v-col>
          <v-col
            cols="2"
            class="d-flex align-center justify-center pa-0 h-100"
            :style="{ backgroundColor: item.backgroundColor }"
          >
            <p class="descriptionPredValues">{{ item.probability }}</p>
          </v-col>
          <!--   <v-col cols="3" class="d-flex align-center justify-center">
            <p class="descriptionPredValues">{{ item.calculation }}</p>
          </v-col> -->
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import { computed } from "vue";
import { useDisplay } from "vuetify";

export default {
  name: "ParamBehavior",

  computed: {
    ...mapState(["mindsetIdealFitData", "user_info"]),

    isVueling() {
      return (
        this.user_info?.tokens?.tokenPE === "CF495946056987" ||
        this.user_info?.tokens?.tokenPE === "CF466958239108"
      );
      //local CF466958239108
      //prod CF495946056987
    },

    isVeritas() {
      return (
        this.user_info?.tokens?.tokenPE === "9999999999999" ||
        this.user_info?.tokens?.tokenPE === "9999999999999"
      );
      //local CF132301683533
      //prod CF122798561545
    },

    // Se ha modificado el mapping para incluir el comportamiento hardcodeado (texto) y
    // el array de valores a buscar para el cálculo de probabilidad
    vuelingMapping() {
      return {
        "WIN TOGETHER": {
          text: "Este valor enfatiza la importancia del trabajo en equipo, la colaboración y el compromiso conjunto para alcanzar los objetivos. Se trata de fomentar un ambiente de cooperación donde cada miembro del equipo aporte su talento y esfuerzo para lograr el éxito colectivo.",
          match: ["Trabajo en equipo", "Compromiso"],
        },
        "MAKE IT HAPPEN": {
          text: "Este valor resalta la capacidad de ejecución, la agilidad y la toma de decisiones efectivas. Se enfoca en actuar con determinación y dinamismo para generar cambios positivos en la organización.",
          match: ["Dinamismo", "Innovación"],
        },
        "MAKE IT BETTER": {
          text: "La mejora continua es la base de este valor. Se trata de cuestionar el status quo, buscar siempre formas más eficaces de hacer las cosas y fomentar la innovación.",
          match: ["Orientación a resultados", "Excelencia"],
        },
        "MAKE IT COST FOCUSED": {
          text: "Se trata de optimizar el uso de los recursos sin comprometer la calidad ni el valor del producto o servicio. La eficiencia financiera es clave para la sostenibilidad y el crecimiento de la empresa.",
          match: ["Estabilidad", "Eficiencia"],
        },
        "MAKE IT CUSTOMER ORIENTED": {
          text: "El cliente es el centro de todas las decisiones y acciones. Este valor impulsa la satisfacción y fidelización de los clientes mediante la entrega de productos y servicios que realmente agreguen valor.",
          match: ["Cooperación", "Adaptación al cliente"],
        },
        "MAKE IT SAFE": {
          text: "La seguridad y el bienestar en el entorno de trabajo son fundamentales. Este valor abarca tanto la protección física de los colaboradores como la seguridad de los procesos y productos.",
          match: ["Responsabilidad", "Bienestar"],
        },
      };
    },

    // Mapping para Veritas con equivalencias específicas
    veritasMapping() {
      return {
        Liderazgo: {
          value: "Liderazgo",
          text: "Cambiamos el mundo desde el supermercado, siendo protagonistas y no espectadores. Tomamos decisiones, aun cuando nadie las ha tomado antes, para dar ejemplo y convencer a los demás para que se sumen a nuestro propósito. Como las abejas, polinizamos el mundo.",
        },
        Compromiso: {
          value: "Pasión",
          text: "Nuestro propósito nos levanta con fuerza cada mañana y da sentido a nuestras vidas. Estamos convencidos de las bondades y beneficios de lo ecológico y ponemos el máximo empeño en nuestro trabajo, disfrutando de cada momento.",
        },
        Integridad: {
          value: "Honestidad",
          text: "El resultado económico y el crecimiento son la consecuencia de seguir fielmente nuestro propósito, y no al revés. Somos de verdad, en nuestra propuesta de productos y servicios, y en nuestra forma de trabajar.",
        },
        Cercanía: {
          value: "Amabilidad",
          text: "Ser amable es ser digno de ser amado. Los pequeños gestos provocan los grandes cambios. Un mundo más amable es un mundo mejor, más justo y unido. Somos motores de amabilidad, con nosotros mismos y con los demás.",
        },
        Sostenibilidad: {
          value: "Impacto",
          text: "Tenemos vocación transformadora. Queremos dejar el mundo mejor de lo que estaba antes de nosotros. Nuestros clientes deben mejorar su salud a través de nuestra alimentación y del conocimiento que les trasladamos. Nuestros proveedores deben estar mejor después de colaborar con nosotros. El planeta y el ecosistema deben respirar mejor después de nuestra actividad.",
        },
      };
    },

    processedParamData() {
      if (!this.mindsetIdealFitData?.ParamProb) return [];

      if (this.isVueling) {
        return this.processVuelingData();
      } else if (this.isVeritas) {
        return this.processVeritasData();
      }

      return this.processNormalData();
    },
  },

  methods: {
    getBackgroundColor(probability) {
      switch (probability) {
        case "Baja":
          return "#FFFC8B";
        case "Media":
          return "#E7F0DE";
        case "Alta":
          return "#B1D095";
        default:
          return "transparent";
      }
    },

    calculateProbabilityText(value) {
      if (value <= 1) return "Baja";
      if (value <= 2) return "Media";
      return "Alta";
    },

    processVuelingData() {
      const vuelingData = [];
      const valores = this.mindsetIdealFitData.ParamValores || [];
      // Se mantiene el array de probabilidades para el cálculo
      const probNums = this.mindsetIdealFitData.ParamProbNum || [];

      Object.entries(this.vuelingMapping).forEach(
        ([vuelingValue, mappingData]) => {
          // Se buscan los índices de los valores definidos en mappingData.match
          const indices = mappingData.match.map((value) =>
            valores.indexOf(value)
          );
          const validProbs = indices
            .filter((i) => i !== -1)
            .map((index) => probNums[index] || 0);

          const avgProb =
            validProbs.reduce((sum, prob) => sum + prob, 0) / validProbs.length;
          const probText = this.calculateProbabilityText(avgProb);

          vuelingData.push({
            value: vuelingValue,
            // Se utiliza el comportamiento hardcodeado
            behaviour: mappingData.text,
            probability: probText,
            backgroundColor: this.getBackgroundColor(probText),
            calculation: `(${validProbs.join(" + ")}) / ${
              validProbs.length
            } = ${avgProb.toFixed(2)}`,
          });
        }
      );

      return vuelingData.sort((a, b) => {
        const probOrder = { Baja: 1, Media: 2, Alta: 3 };
        return probOrder[a.probability] - probOrder[b.probability];
      });
    },

    processVeritasData() {
      const veritasData = [];
      const valores = this.mindsetIdealFitData.ParamValores || [];
      const probabilities = this.mindsetIdealFitData.ParamProb || [];

      // Iterar por los valores de CFAM y buscar equivalentes en el mapping de Veritas
      valores.forEach((valor, index) => {
        // Comprobar si el valor está en el mapping de Veritas
        if (this.veritasMapping[valor]) {
          const mappingData = this.veritasMapping[valor];

          veritasData.push({
            value: mappingData.value, // Valor Veritas equivalente
            behaviour: mappingData.text, // Comportamiento fijo de Veritas
            probability: probabilities[index] || "Media", // Mantener la probabilidad original
            backgroundColor: this.getBackgroundColor(
              probabilities[index] || "Media"
            ),
          });
        }
      });

      return veritasData.sort((a, b) => {
        const probOrder = { Baja: 1, Media: 2, Alta: 3 };
        return probOrder[a.probability] - probOrder[b.probability];
      });
    },

    processNormalData() {
      return (this.mindsetIdealFitData.ParamProb || [])
        .map((prob, index) => ({
          value: this.mindsetIdealFitData.ParamValores?.[index] || "",
          behaviour:
            this.mindsetIdealFitData.ParamComportamientos?.[index] || "",
          probability: prob,
          backgroundColor: this.getBackgroundColor(prob),
          calculation: `Valor único: ${this.mindsetIdealFitData.ParamProbNum[index]}`,
        }))
        .sort((a, b) => {
          const probOrder = { Baja: 1, Media: 2, Alta: 3 };
          return probOrder[a.probability] - probOrder[b.probability];
        });
    },
  },

  setup() {
    const { mdAndUp } = useDisplay();
    const { name } = useDisplay();

    const principles_card_height = computed(() => {
      const baseHeight =
        name.value === "xs" || name.value === "sm"
          ? "auto"
          : (92 * window.innerHeight) / 100;

      if (baseHeight === "auto") return baseHeight;

      switch (name.value) {
        case "md":
          return (80 * baseHeight) / 100;
        case "lg":
          return (74 * baseHeight) / 100;
        case "xl":
        case "xxl":
          return (83 * baseHeight) / 100;
        default:
          return baseHeight;
      }
    });

    return {
      principles_card_height,
      mdAndUp,
    };
  },
};
</script>

<style>
.principles_card {
  overflow-y: auto;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #efefef;
  max-height: 80vh;
}

.data-entry {
  min-height: 60px;
  border-bottom: 1px solid #e0e0e0;
}

.descriptionPredValues {
  color: #636363;
  font-size: 13px;
  line-height: 1.4;
  padding: 8px;
  margin: 0;
  text-align: center;
}

.borderblackeEV {
  border-bottom: 1px solid #34495e;
  height: 50px;
}

.principles_card::-webkit-scrollbar {
  width: 10px;
}

.principles_card::-webkit-scrollbar-thumb {
  background-color: #00c3c0;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.principles_card::-webkit-scrollbar-track {
  border-radius: 10px;
}

@media (max-width: 768px) {
  .descriptionPredValues {
    font-size: 12px;
  }

  .data-entry {
    min-height: 50px;
  }
}

@media (max-width: 600px) {
  .descriptionPredValues {
    font-size: 11px;
  }

  .data-entry {
    min-height: 40px;
  }
}
</style>
