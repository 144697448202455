import axios from "axios";

export default () => {
  //const back_dev = "http://127.0.0.1:8000/api"; // para pruebas pegandole a la base de datos local
  const back_dev = "https://culturalfit.es/api";
  const back_prod = "https://apis.culturalfit.es/api";

  const front_prod = "https://intranet.culturalfitsolutions.com/";
  const local_host = "http://localhost";
  const localbbdd = back_dev;
  const axiosConfig = axios.create({
    baseURL: window.location.href.includes(front_prod)
      ? back_prod
      : window.location.href.includes(local_host)
      ? localbbdd
      : back_dev,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
    },
    // Aumentar timeout y límites:
    timeout: 120000, // 2 minutos, por ejemplo
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
  });

  axiosConfig.interceptors.request.use(
    (request) => {
      request.metadata = { startTime: new Date() };
      request.headers.authorization =
        "Bearer " + sessionStorage.getItem("authorisation_token");
      return request;
    },
    (error) => {
      if (error.response.status === 404) {
        throw new Error("Not found");
      }
      return Promise.reject(error);
    }
  );

  return axiosConfig;
};
