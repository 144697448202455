<script setup>
import { computed, ref, onMounted } from "vue"; // Agregamos ref y onMounted
import { useStore } from "vuex";
import { defineProps } from "vue";
import DictionaryForReport from "@/globals/DictionaryForReport";
import greenCircleImage from "@/assets/report-images/green-circle.png";
import diagonalLinesImage from "@/assets/report-images/diagonal-lines.png";
import dotsImage from "@/assets/report-images/dots.png";
import minzerLogoImage from "@/assets/report-images/minzer-logo.png";
import linesImage from "@/assets/report-images/lines.png";

const store = useStore();
const companyLogoBase64 = ref(null); // Agregamos ref para el logo

defineProps({
  reportData: Object,
});

const profileReportTitle = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][1];
});
const footerText = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][3];
});
const DateText = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][63];
});

const DevelopFor = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][130];
});

// Agregamos la lógica para la posición
const position = computed(() => {
  return Number(store.state.user_info?.position) || 0;
});

// Agregamos la lógica para obtener la URL del logo
const getLogoUrl = computed(() => {
  if (position.value === 1) {
    const encodedCompanyName = encodeURIComponent(
      store.state.user_info?.company_name
    );
    return `https://cfs-report.s3.eu-south-2.amazonaws.com/elogo/${encodedCompanyName}.png`;
  }
  return null;
});

// Función para convertir imagen a base64
async function convertImgToBase64(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = url;
    img.onload = function () {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      resolve(canvas.toDataURL("image/png"));
    };
    img.onerror = function () {
      reject(new Error("No se pudo cargar la imagen"));
    };
  });
}

// Agregamos onMounted para cargar el logo
onMounted(async () => {
  if (getLogoUrl.value) {
    try {
      companyLogoBase64.value = await convertImgToBase64(getLogoUrl.value);
    } catch (error) {
      console.error("Error convirtiendo la imagen de AWS a Base64:", error);
    }
  }
});
</script>

<template>
  <div id="coverPage" class="page">
    <div class="page-body">
      <img :src="greenCircleImage" class="page1-green-circle" />
      <img :src="diagonalLinesImage" class="page1-diagonal-lines" />
      <img :src="dotsImage" class="page1-dots" />

      <img :src="linesImage" class="page1-lines-logo" />
      <div class="cover-data">
        <img :src="minzerLogoImage" class="page1-minzer-logo" />

        <!-- Solo agregamos la sección del logo de empresa -->
        <div v-if="position === 1" class="company-logo-section">
          <p class="elaborado-para">{{ DevelopFor }}</p>
          <img
            v-if="companyLogoBase64"
            :src="companyLogoBase64"
            class="company-logo"
            alt="Logo empresa"
          />
        </div>
        <div class="title">{{ profileReportTitle }}</div>
        <div class="green-field mt-20">{{ reportData.name }}</div>
        <div class="small-dark-green-field">
          <strong>ID:</strong> {{ reportData.tokenText }}
        </div>
        <div class="small-dark-green-field">
          <strong>E-mail:</strong> {{ reportData.email.replace("CF-", "") }}
        </div>
      </div>
      <div class="cover-green-rect"></div>
      <div class="cover-dark-green-rect"></div>
      <div class="cover-date small-dark-green-field">
        <strong>{{ DateText }}:</strong>
        {{ new Date().toLocaleDateString("es-ES") }}
      </div>
    </div>
    <div class="page-footer">
      {{ footerText }}
    </div>
  </div>
</template>

<style scoped>
.page1-green-circle {
  display: block;
  width: 180px;
  position: absolute;
  left: 32px;
}

.page1-diagonal-lines {
  display: block;
  width: 168px;
  position: absolute;
  margin-top: 32px;
  right: 32px;
}

.page1-dots {
  display: block;
  width: 183px;
  position: absolute;
  margin-top: 166px;
  left: 88px;
}

.page1-minzer-logo {
  width: 289px;
}

.page1-lines-logo {
  display: block;
  width: 58px;
  position: absolute;
  margin-top: 514px;
  left: 129px;
}

/* Agregamos los estilos para el logo de empresa */
.company-logo-section {
  width: 289px;
  margin-bottom: 55px;
}

.elaborado-para {
  font-size: 14px;
  color: #33495f;
  font-weight: 500;
  margin-bottom: 15px;
}

.company-logo {
  max-width: 200px;
  max-height: 100px;
  width: auto;
  height: auto;
}

.cover-data {
  position: absolute;
  left: 290px;
  margin-top: 290px;
  text-align: center;
}

.cover-date {
  position: absolute;
  right: 116px;
  margin-top: 913px;
  text-align: right;
}

.cover-green-rect {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 65px;
  margin-top: 860px;
  background-color: #2eb6b9;
}

.cover-dark-green-rect {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 54px;
  margin-top: 818px;
  background-color: #33495f;
}

.title {
  font-size: 18px;
  color: #33495f;
  font-weight: bold;
}

.green-field {
  font-size: 24px;
  color: #2eb6b9;
  font-weight: bold;
}

.small-dark-green-field {
  font-size: 14px;
  color: #33495f;
}

.mt-20 {
  margin-top: 20px;
}
</style>
