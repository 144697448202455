<script setup>
import { defineProps, computed } from "vue";
import { useStore } from "vuex";
import DictionaryForReport from "@/globals/DictionaryForReport";
import linesImage from "@/assets/report-images/lines.png";
import CandidateReportKPILegend from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReportKPILegend.vue";

const props = defineProps({
  reportData: Object,
  chartColors: Array,
  userInfo: Object,
});

const store = useStore();
const WIN_TOGETHER = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][133];
});
const MAKE_IT_HAPPEN = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][134];
});
const MAKE_IT_BETTER = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][135];
});
const MAKE_IT_COST_FOCUSED = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][136];
});
const MAKE_IT_CUSTOMER_ORIENTED = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][137];
});
const MAKE_IT_SAFE = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][138];
});

// Valores y comportamientos para Vueling
const vuelingValuesAndBehaviours = {
  "WIN TOGETHER": WIN_TOGETHER,
  "MAKE IT HAPPEN": MAKE_IT_HAPPEN,
  "MAKE IT BETTER": MAKE_IT_BETTER,
  "MAKE IT COST FOCUSED": MAKE_IT_COST_FOCUSED,
  "MAKE IT CUSTOMER ORIENTED": MAKE_IT_CUSTOMER_ORIENTED,
  "MAKE IT SAFE": MAKE_IT_SAFE,
};

// Valores y comportamientos para Veritas
const veritasValuesAndBehaviours = {
  Liderazgo:
    "Cambiamos el mundo desde el supermercado, siendo protagonistas y no espectadores. Tomamos decisiones, aun cuando nadie las ha tomado antes, para dar ejemplo y convencer a los demás para que se sumen a nuestro propósito. Como las abejas, polinizamos el mundo.",
  Pasión:
    "Nuestro propósito nos levanta con fuerza cada mañana y da sentido a nuestras vidas. Estamos convencidos de las bondades y beneficios de lo ecológico y ponemos el máximo empeño en nuestro trabajo, disfrutando de cada momento.",
  Honestidad:
    "El resultado económico y el crecimiento son la consecuencia de seguir fielmente nuestro propósito, y no al revés. Somos de verdad, en nuestra propuesta de productos y servicios, y en nuestra forma de trabajar.",
  Amabilidad:
    "Ser amable es ser digno de ser amado. Los pequeños gestos provocan los grandes cambios. Un mundo más amable es un mundo mejor, más justo y unido. Somos motores de amabilidad, con nosotros mismos y con los demás.",
  Impacto:
    "Tenemos vocación transformadora. Queremos dejar el mundo mejor de lo que estaba antes de nosotros. Nuestros clientes deben mejorar su salud a través de nuestra alimentación y del conocimiento que les trasladamos. Nuestros proveedores deben estar mejor después de colaborar con nosotros. El planeta y el ecosistema deben respirar mejor después de nuestra actividad.",
};

// Mapeo de valores CFAM a valores Veritas
const veritasValueMapping = {
  Liderazgo: "Liderazgo",
  Compromiso: "Pasión",
  Integridad: "Honestidad",
  Cercanía: "Amabilidad",
  Sostenibilidad: "Impacto",
};

// Método 1: Comprobar por token
function isVuelingByToken() {
  return (
    props.userInfo?.tokens?.tokenPE === "CF495946056987" ||
    props.userInfo?.tokens?.tokenPE === "CF466958239108"
  );
}

function isVeritasByToken() {
  return (
    props.userInfo?.tokens?.tokenPE === "9999999999999" ||
    props.userInfo?.tokens?.tokenPE === "9999999999999"
  );
}

// Método 2: Comprobar por bandera en los datos
function hasProjectTypeFlag(type) {
  if (
    !props.reportData?.ParamBehaviours ||
    !props.reportData.ParamBehaviours.length
  )
    return false;

  return props.reportData.ParamBehaviours.some(
    (param) => param.projectType === type
  );
}

// Método 3: Comprobar por valores
function hasVeritasValues() {
  if (!props.reportData?.ParamBehaviours) return false;

  const veritasValuesList = [
    "Liderazgo",
    "Pasión",
    "Honestidad",
    "Amabilidad",
    "Impacto",
  ];

  // Contar cuántos valores coinciden con valores de Veritas
  const matchCount = props.reportData.ParamBehaviours.filter((param) =>
    veritasValuesList.includes(param.value)
  ).length;

  // Si más de la mitad son valores de Veritas, considerarlo Veritas
  return matchCount >= Math.min(3, props.reportData.ParamBehaviours.length / 2);
}

// Funciones finales combinando los métodos
function isVuelingValues() {
  return isVuelingByToken() || hasProjectTypeFlag("vueling");
}

function isVeritasValues() {
  // Usar cualquiera de los tres métodos
  return (
    isVeritasByToken() || hasProjectTypeFlag("veritas") || hasVeritasValues()
  );
}

function getBehaviour(value) {
  if (isVuelingValues() && vuelingValuesAndBehaviours[value]) {
    return vuelingValuesAndBehaviours[value];
  }

  if (isVeritasValues()) {
    // Si el valor ya es uno de Veritas
    if (veritasValuesAndBehaviours[value]) {
      return veritasValuesAndBehaviours[value];
    }

    // Si es un valor CFAM que se mapea a Veritas
    if (veritasValueMapping[value]) {
      const veritasValue = veritasValueMapping[value];
      return veritasValuesAndBehaviours[veritasValue];
    }
  }

  return "";
}

function getDisplayValue(value) {
  if (isVeritasValues() && veritasValueMapping[value]) {
    return veritasValueMapping[value];
  }
  return value;
}

const ProHigh = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][106];
});

const ProMedium = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][107];
});

function getRowClass(value) {
  if (value == ProHigh.value) return "high";
  else if (value == ProMedium.value) return "medium";
  else return "low";
}

let candidateOrgFitColor = computed(() => {
  return calculateFitKPIColor(props.reportData.mindsetFitIdealPercent);
});

let candidateOrgFitBgColor = computed(() => {
  return calculateFitKPIBackgroundColor(
    props.reportData.mindsetFitIdealPercent
  );
});

function calculateFitKPIColor(value) {
  if (value < 70) return "#F7E66B";
  if (value > 80) return "#5d8045";
  if (value >= 70 && value <= 80) return "#CADFB8";
  return "#F7E66B";
}

function calculateFitKPIBackgroundColor(value) {
  if (value < 70) return "#FBF3BB";
  if (value > 80) return "#C9E1B9";
  if (value >= 70 && value <= 80) return "#E7F0DE";
  return "#FBF3BB";
}

const ThisRepre = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][29];
});

const AdjustIdeal = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][108];
});

const kpiAdjust = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][110];
});

const footerText = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][3];
});

const ValOf = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][131];
});

const TTable = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][132];
});

const Val = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][53];
});

const Prob = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][55];
});
</script>

<template>
  <div id="appendix1Page1" class="page">
    <div class="page-body">
      <img :src="linesImage" class="page1-lines-logo" />

      <div class="main-container">
        <div class="title">
          {{ AdjustIdeal }}
          <strong>{{ reportData.name }}</strong> &
          <strong>{{ reportData.paramName }}</strong>
        </div>

        <p class="small-dark-green-field">
          {{ ThisRepre }}
        </p>

        <div class="title-s center">
          {{ kpiAdjust }} {{ reportData.name }} &
          <strong>{{ reportData.paramName }}</strong>
        </div>
        <div class="kpi center">
          <v-progress-circular
            :rotate="90"
            :size="120"
            :width="8"
            :model-value="reportData.mindsetFitIdealPercent"
            :color="candidateOrgFitColor"
            bg-color="transparent"
          >
            <v-avatar size="104" :color="candidateOrgFitBgColor">
              <div class="kpi-value-number">
                {{ reportData.mindsetFitIdealPercent }}
              </div>
            </v-avatar>
          </v-progress-circular>
        </div>
        <CandidateReportKPILegend />

        <div class="subtitle">
          {{ ValOf }}
          <strong>{{ reportData.paramName }}</strong
          >:
        </div>
        <p class="small-dark-green-field" v-html="TTable"></p>
        <p class="small-dark-green-field mt-n4">
          <strong> {{ reportData.paramName }}</strong>
        </p>

        <v-table v-bind="props" class="mt-n5">
          <tr>
            <td class="dark-green">{{ Val }}</td>
            <td
              v-if="isVuelingValues() || isVeritasValues()"
              class="dark-green"
            >
              Comportamientos
            </td>
            <td class="dark-green">{{ Prob }}</td>
          </tr>

          <tr v-for="(value, key) in reportData.ParamBehaviours" :key="key">
            <td class="light-green-dt">
              {{
                isVeritasValues() ? getDisplayValue(value.value) : value.value
              }}
            </td>
            <td
              v-if="isVuelingValues() || isVeritasValues()"
              class="regular-cell small-text-vuel"
            >
              {{ getBehaviour(value.value) }}
            </td>
            <td class="regular-cell" :class="getRowClass(value.probability)">
              {{ value.probability }}
            </td>
          </tr>
        </v-table>
      </div>
    </div>
    <div class="page-footer">
      {{ footerText }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.small-text-vuel {
  font-size: 10px !important;
}
.chart {
  width: 260px;
  height: 260px;
  margin-left: auto;
  margin-right: auto;
}
.chart2 {
  width: 630px;
  height: 630px;
  margin-left: auto;
  margin-right: auto;
}
.page1-lines-logo {
  display: block;
  width: 58px;
  position: absolute;
  margin-top: 380px;
  right: 0px;
  transform: rotateY(180deg);
}
table {
  td {
    border: none !important;
  }
}
</style>
