<template>
  <!-- Spinner overlay -->
  <!--   <div class="custom-loading-overlay" v-if="isLoading">
    <div class="custom-spinner" style="width: 84px; height: 84px">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
        <path
          d="M50 10L85 30v40L50 90L15 70V30L50 10z"
          fill="none"
          stroke="#00c3c0"
          stroke-width="4"
          stroke-linecap="round"
        >
          <animate
            attributeName="stroke-dasharray"
            values="0,250;250,0"
            dur="2s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="stroke-dashoffset"
            values="0;-250"
            dur="4s"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </div>
    <p style="font-weight: bold; margin-top: 16px; color: #00c3c0">
      Cargando...
    </p>
  </div> -->

  <div :class="mdAndUp ? 'd-flex flex-row' : ''">
    <!--Left Sheet-->
    <v-sheet
      color="transparent"
      :height="left_sheet_height"
      :width="left_sheet_width"
    >
      <!--Buttons Section-->
      <v-sheet
        height="100%"
        width="100%"
        :color="mdAndUp ? 'white' : 'transparent'"
        :class="mdAndUp ? 'd-flex align-center' : 'd-flex align-center mt-10'"
        :elevation="mdAndUp ? 2 : 0"
      >
        <ProfilesEvaluationButtonsSection />
      </v-sheet>
    </v-sheet>

    <!--Right Sheet-->
    <v-sheet
      class=""
      color="transparent"
      :height="left_sheet_height"
      :width="right_sheet_width"
    >
      <v-sheet :height="right_sheet_height" width="100%" color="transparent">
        <!--Table-->
        <v-row dense>
          <v-col cols="12">
            <div>
              <CandidatesDesktopTable
                :table_top_sheet_height="table_top_sheet_height"
                :table_sheet_height="table_sheet_height"
                :candidates_count="candidates_count"
                :isLoadingAllCandidates="isLoadingAllCandidates"
                :totalCandidatesLoaded="totalCandidatesLoaded"
                :totalCandidatesCount="totalCandidatesCount"
                :progressPercentage="progressPercentage"
              />
            </div>
            <!--     <div v-else>
              <CandidatesMobileTable
                :itemsPerPage="itemsPerPage"
                :candidates_count="candidates_count"
              /> 
            </div>-->
          </v-col>
        </v-row>
      </v-sheet>

      <!--Buttons Section for Mobile Responsive-->
      <v-sheet
        class="hidden-md-and-up"
        color="transparent"
        :height="left_sheet_height"
        :width="left_sheet_width"
      >
        <v-sheet
          height="100%"
          width="100%"
          color="transparent"
          class="d-flex align-center hidden-md-and-up"
          elevation="0"
        >
          <ProfilesEvaluationButtonsSectionMobile />
        </v-sheet>
      </v-sheet>

      <!--Footer-->
      <v-sheet
        :height="footer_height"
        width="100%"
        color="transparent"
        class="d-flex align-center"
      >
        <v-row wrap no-gutters :class="mdAndUp ? '' : 'mx-3 mb-3'">
          <v-col cols="12" md="12">
            <div class="text-justify footer bg-transparent w-100 h-100">
              <p :class="mdAndUp ? 'mx-6' : ''">
                {{ footer }}
              </p>
            </div>
          </v-col>
        </v-row>
      </v-sheet>
    </v-sheet>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapState, mapMutations } from "vuex";
import { computed } from "vue";
import { useDisplay } from "vuetify";

//API Services
import ProfileEvaluationService from "@/services/ProfileEvaluationService";
import TagsService from "@/services/TagsService";

// Components
import ProfilesEvaluationButtonsSection from "@/components/DashboardComponents/ProfilesEvaluation/ProfilesEvaluationButtonsSection.vue";
import ProfilesEvaluationButtonsSectionMobile from "@/components/DashboardComponents/ProfilesEvaluation/ProfilesEvaluationButtonsSectionMobile.vue";
import CandidatesDesktopTable from "@/components/DashboardComponents/ProfilesEvaluation/CandidatesDesktopTable.vue";
/* import CandidatesMobileTable from "@/components/DashboardComponents/ProfilesEvaluation/CandidatesMobileTable.vue"; */

export default defineComponent({
  name: "CandidatesGeneralView",
  data() {
    return {
      isLoadingAllCandidates: false,
      totalCandidatesLoaded: 0,
      totalCandidatesCount: 0,
      progressPercentage: 0,
      batchSize: 30,
      /*       isLoading: false, */
      //Table Title
      table_title: "Evaluación de Perfiles",

      height: window.innerHeight,
      label: "Etiquetas",

      //Text Field Search Placeholder
      search_placeholder: "Escribe y filtra por la palabra que quieras",
      filter: "",

      //Components Props
      candidates_count: 0,

      footer: "",
    };
  },

  components: {
    ProfilesEvaluationButtonsSection,
    CandidatesDesktopTable,
    /*   CandidatesMobileTable, */
    ProfilesEvaluationButtonsSectionMobile,
  },

  computed: {
    ...mapState([
      "user_info",
      "detailed_view_step",
      "search_table",
      "candidates",
      "search",
      "defaultParametrization",
    ]),
  },

  methods: {
    // Modifica solo el método loadAllCandidatesProgressively para optimizar la carga
    async loadAllCandidatesProgressively() {
      this.isLoadingAllCandidates = true;
      this.totalCandidatesLoaded = 0;

      try {
        // Primero, obtenemos el total de candidatos con la primera petición pequeña
        const countResponse =
          await ProfileEvaluationService.loadCandidatesGeneralView(
            this.user_info.tokens.tokenPE,
            "es",
            1, // Primera página
            1, // Solo necesitamos metadatos, no datos reales
            { search: this.search_table }
          );

        if (!countResponse || !countResponse.data) {
          console.error("La respuesta no es válida:", countResponse);
          this.isLoadingAllCandidates = false;
          return;
        }

        // Guardar el número total de candidatos y el enlace
        this.totalCandidatesCount = countResponse.data.filas || 0;
        this.candidates_count = countResponse.data.filas || 0;

        this.setExternal_link(countResponse.data.linkCandidato);

        // Si no hay candidatos, terminar
        if (this.totalCandidatesCount === 0) {
          console.log("No hay candidatos para cargar");
          this.setEmptyCandidates();
          this.isLoadingAllCandidates = false;
          return;
        }

        // MEJORA CLAVE: Optimizar estrategia de carga según cantidad de datos
        const totalCandidates = this.totalCandidatesCount;
        let maxConcurrentRequests, batchSize;

        // Configuración optimizada según cantidad de candidatos
        if (totalCandidates <= 200) {
          // Para pocos candidatos, una sola petición grande
          batchSize = totalCandidates;
          maxConcurrentRequests = 1;
        } else if (totalCandidates <= 500) {
          // Para tamaño medio, 2-3 peticiones simultáneas
          batchSize = totalCandidates;
          maxConcurrentRequests = 1;
        } else if (totalCandidates <= 1000) {
          // Para tamaño grande, muchas peticiones pequeñas simultáneas
          batchSize = totalCandidates;
          maxConcurrentRequests = 1;
        } else {
          // Para tamaños enormes, más peticiones simultáneas con tamaño moderado
          batchSize = totalCandidates;
          maxConcurrentRequests = 1;
        }

        // Calcular número total de lotes
        const totalBatches = Math.ceil(totalCandidates / batchSize);

        // Inicializar estructura para acumular datos
        const allData = {
          filas: this.totalCandidatesCount,
          linkCandidato: countResponse.data.linkCandidato || "",
          fechaRegistro: [],
          email: [],
          nombreCompleto: [],
          mindsetFIT: [],
          arqTXT: [],
          parameter: [],
          tags: [],
          linkToken: [],
        };

        // MEJORA CLAVE: Implementar carga por lotes con paralelismo controlado
        // Procesamos los lotes en grupos para mantener el paralelismo controlado
        for (
          let startBatch = 0;
          startBatch < totalBatches;
          startBatch += maxConcurrentRequests
        ) {
          const endBatch = Math.min(
            startBatch + maxConcurrentRequests,
            totalBatches
          );
          const batchPromises = [];

          // Crear promesas para este grupo de lotes
          for (let i = startBatch; i < endBatch; i++) {
            const page = i + 1;
            batchPromises.push(
              ProfileEvaluationService.loadCandidatesGeneralView(
                this.user_info.tokens.tokenPE,
                "es",
                page,
                batchSize,
                { search: this.search_table }
              )
            );
          }

          // Esperamos a que todas las promesas de este grupo se completen
          const batchResults = await Promise.allSettled(batchPromises);

          // Procesamos los resultados de este grupo
          for (let i = 0; i < batchResults.length; i++) {
            const result = batchResults[i];
            const batchIndex = startBatch + i;
            const page = batchIndex + 1;

            if (
              result.status === "fulfilled" &&
              result.value &&
              result.value.data
            ) {
              const batchData = result.value.data;

              // Añadir datos al acumulador
              if (batchData.fechaRegistro)
                allData.fechaRegistro.push(...batchData.fechaRegistro);
              if (batchData.email) allData.email.push(...batchData.email);
              if (batchData.nombreCompleto)
                allData.nombreCompleto.push(...batchData.nombreCompleto);
              if (batchData.mindsetFIT)
                allData.mindsetFIT.push(...batchData.mindsetFIT);
              if (batchData.arqTXT) allData.arqTXT.push(...batchData.arqTXT);
              if (batchData.parameter)
                allData.parameter.push(...batchData.parameter);
              if (batchData.tags) allData.tags.push(...batchData.tags);
              if (batchData.linkToken)
                allData.linkToken.push(...batchData.linkToken);
            } else {
              console.error(
                `Error cargando lote ${page}:`,
                result.reason || "Respuesta inválida"
              );
            }
          }

          // Actualizar contador y progreso
          this.totalCandidatesLoaded = allData.nombreCompleto.length;
          this.updateProgress();

          // Actualizar la UI con los datos acumulados hasta ahora
          this.setCandidates({ ...allData });
        }
      } catch (error) {
        console.error("Error general cargando candidatos:", error);
      } finally {
        this.isLoadingAllCandidates = false;
      }
    },

    // Actualiza el indicador de progreso
    updateProgress() {
      if (this.totalCandidatesCount > 0) {
        this.progressPercentage = Math.floor(
          (this.totalCandidatesLoaded / this.totalCandidatesCount) * 100
        );
      } else {
        this.progressPercentage = 0;
      }
    },
    ...mapMutations([
      "setCandidates",
      "setTags_list",
      "setExternal_link",
      "setEmptyCandidates",
      "setTotalParametization",
      "setDefaultParametrization",
      "setTeamAndCompanyAdjustment",
    ]),

    async loadCandidatesGeneralView() {
      try {
        const response =
          await ProfileEvaluationService.loadCandidatesGeneralView(
            this.user_info.tokens.tokenPE,
            "es"
          );
        let data = response.data;

        if (data.filas == 0) {
          this.candidates_count = 0;
          this.setExternal_link(data.linkCandidato);
          this.setEmptyCandidates();
        } else {
          this.candidates_count = data.filas;
          this.setExternal_link(data.linkCandidato);
          this.setCandidates(data);
        }
      } catch (error) {
        console.log(error);
      }
    },

    async loadAllTags() {
      try {
        const response = await TagsService.getAllTags(
          this.user_info.tokens.tokenPE
        );
        let data = response.data;
        this.setTags_list(data);
      } catch (error) {
        console.log(error);
      }
    },
    async getDefaultParametrization() {
      try {
        const response =
          await ProfileEvaluationService.getDefaultParametrization(
            this.user_info.tokens.tokenPE
          );
        this.setDefaultParametrization(response.data);
      } catch (error) {
        console.log(error);
      }
    },
    async getListofIDealParams() {
      try {
        const response = await ProfileEvaluationService.getListofIDealParams(
          this.user_info.tokens.tokenPE
        );
        let data = response.data;
        this.setTotalParametization(data);
      } catch (error) {
        console.log(error);
      }
    },
    async reqProjectListDataRaw(projectToken) {
      try {
        const response = await ProfileEvaluationService.getProjectListDataRaw(
          projectToken
        );

        if (response.status !== 200)
          console.log(`No hay proyecto con token ${projectToken}`);

        this.setTeamAndCompanyAdjustment(response.data);
      } catch (error) {
        console.log(error);
      }
    },
    set_texts() {
      let t = sessionStorage.getItem("texts");
      let texts = JSON.parse(t);
      this.footer = texts[62];
    },
  },

  async created() {
    /*  this.isLoading = true; */
    try {
      await this.getDefaultParametrization();
      // Reemplazar la carga normal con la progresiva
      await this.loadAllCandidatesProgressively();
      await this.getListofIDealParams();
      await this.loadAllTags();
      await this.reqProjectListDataRaw(this.user_info.tokens.tokenDC);
      this.set_texts();
    } catch (error) {
      console.log(error);
    } finally {
      /*    this.isLoading = false; */
    }
  },
  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  },

  watch: {
    detailed_view_step(newVal) {
      if (newVal === 0) {
        this.getDefaultParametrization();
        this.getListofIDealParams();
      }
    },
  },

  setup() {
    const { mdAndUp } = useDisplay();
    const { lgAndUp } = useDisplay();
    const { xsAndUp } = useDisplay();
    const { name } = useDisplay();

    const left_sheet_height = computed(() => {
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (92 * window.innerHeight) / 100;
        case "lg":
          return (92 * window.innerHeight) / 100;
        case "xl":
          return (92 * window.innerHeight) / 100;
        case "xxl":
          return (92 * window.innerHeight) / 100;
      }
      return undefined;
    });

    const left_sheet_width = computed(() => {
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (18 * window.innerWidth) / 100;
        case "lg":
          return (18 * window.innerWidth) / 100;
        case "xl":
          return (18 * window.innerWidth) / 100;
        case "xxl":
          return (18 * window.innerWidth) / 100;
      }
      return undefined;
    });

    const right_sheet_width = computed(() => {
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (82 * window.innerWidth) / 100;
        case "lg":
          return (82 * window.innerWidth) / 100;
        case "xl":
          return (82 * window.innerWidth) / 100;
        case "xxl":
          return (82 * window.innerWidth) / 100;
      }
      return undefined;
    });

    const right_sheet_height = computed(() => {
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (94 * ((92 * window.innerHeight) / 100)) / 100;
        case "lg":
          return (94 * ((92 * window.innerHeight) / 100)) / 100;
        case "xl":
          return (94 * ((92 * window.innerHeight) / 100)) / 100;
        case "xxl":
          return (94 * ((92 * window.innerHeight) / 100)) / 100;
      }
      return undefined;
    });

    const table_top_sheet_height = computed(() => {
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (10 * ((94 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "lg":
          return (15 * ((94 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xl":
          return (13 * ((94 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xxl":
          return (12 * ((94 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
      }
      return undefined;
    });

    const table_sheet_height = computed(() => {
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (
            (90 * ((94 * ((92 * window.innerHeight) / 100)) / 100)) / 100 - 20
          );
        case "lg":
          return (
            (77 * ((94 * ((92 * window.innerHeight) / 100)) / 100)) / 100 - 20
          );
        case "xl":
          return (
            (83 * ((94 * ((92 * window.innerHeight) / 100)) / 100)) / 100 - 20
          );
        case "xxl":
          return (
            (84 * ((94 * ((92 * window.innerHeight) / 100)) / 100)) / 100 - 20
          );
      }
      return undefined;
    });

    const footer_height = computed(() => {
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (6 * ((92 * window.innerHeight) / 100)) / 100;
        case "lg":
          return (6 * ((92 * window.innerHeight) / 100)) / 100;
        case "xl":
          return (6 * ((92 * window.innerHeight) / 100)) / 100;
        case "xxl":
          return (6 * ((92 * window.innerHeight) / 100)) / 100;
      }
      return undefined;
    });

    const itemsPerPage = computed(() => {
      switch (name.value) {
        case "md":
          return 8;
        case "lg":
          return 10;
        case "xl":
          return 15;
        case "xxl":
          return 20;
      }
      return undefined;
    });

    return {
      left_sheet_height,
      left_sheet_width,
      right_sheet_height,
      table_top_sheet_height,
      table_sheet_height,
      right_sheet_width,
      footer_height,
      itemsPerPage,
      mdAndUp,
      lgAndUp,
      xsAndUp,
    };
  },
});
</script>

<style scoped>
.search_tf {
  font-style: italic;
}

.container {
  background-color: #fbfbfb;
}

.footer {
  font-size: 60%;
  font-style: italic;
  color: #585858;
}

.filter_icon {
  background-color: #00c3c0;
}

.testing {
  height: 100% !important;
}

/* Estilos para el spinner */
.custom-loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.custom-spinner {
  animation: spin 5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
