<template>
  <div class="text-center mt-n15">
    <apexchart
      type="radar"
      :height="chart_height"
      :options="chartOptions"
      :series="this.seriesForChart()"
    ></apexchart>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { useDisplay } from "vuetify";
import { computed } from "vue";
import Values from "@/globals/Values";
import Utils from "@/globals/Utils";

const { list36AllZero } = Values;

export default {
  name: "Desired_OrgCulturalEngagementChart",

  data: () => ({
    series: [
      {
        name: "Valores culturales",
        data: list36AllZero,
      },
      {
        name: "Valores de la persona",
        data: list36AllZero,
      },
    ],
    chartOptions: {
      chart: {
        height: 350,
        type: "radar",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: [
          "Cercanía",
          "Dinamismo",
          "Orientación a resultados",
          "Formalidad",
          "Apertura al cambio",
          "Bienestar",
          "Generosidad",
          "Creatividad",
          "Excelencia",
          "Coordinación",
          "Enfoque analítico",
          "Integridad",
          "Trabajo en equipo",
          "Autonomía",
          "Competitividad",
          "Estabilidad",
          "Cooperación",
          "Transparencia",
          "Lealtad",
          "Innovación",
          "Pragmatismo",
          "Responsabilidad",
          "Tolerancia al fallo",
          "Respeto",
          "Confianza",
          "Tolerancia al riesgo",
          "Ambición",
          "Perseverancia",
          "Orientación al aprendizaje",
          "Diversidad",
          "Compromiso",
          "Atrevimiento",
          "Liderazgo",
          "Eficiencia",
          "Adaptación al cliente",
          "Sostenibilidad",
        ],
        labels: {
          show: true,
          style: {
            colors: [
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
            ],
            fontSize: "9px",
            fontFamily: "Arial",
          },
        },
      },

      yaxis: {
        show: false,
      },
      legend: {
        show: true,
        offsetY: -24,
        labels: {
          colors: "#34495e",
        },
        fontWeight: 600,
        fontSize: "11px",
        markers: {
          width: 12,
          height: 12,
          strokeWidth: 0,
          strokeColor: "#fff",
          fillColors: ["#00c3c0", "#34495e"],
          radius: 0,
          onClick: undefined,
        },
      },
      stroke: {
        width: 1,
        colors: ["#00c3c0", "#34495e"],
      },
      markers: {
        size: 0,
      },
      fill: {
        colors: ["#00c3c0", "#34495e"],
      },
    },
  }),

  computed: {
    ...mapState(["candidateSurveyResult", "averageInnerSurvey"]),
  },

  methods: {
    seriesForChart() {
      const candidateSurvey = this.candidateSurveyResult ?? list36AllZero;
      const projectAverageSurvey = this.averageInnerSurvey ?? list36AllZero;

      const cultureAverageSurveyData = Object.values(
        projectAverageSurvey?.averageSurviesResults?.cultureOrganizationBlock
      );
      const candidateSurveyData = Object.values(candidateSurvey[1].data);
      const dataForChart = [
        {
          name: "Valores culturales",
          data: Utils.transformaDataList(cultureAverageSurveyData),
        },
        {
          name: "Valores de la persona",
          data: Utils.transformaDataList(candidateSurveyData),
        },
      ];

      return dataForChart;
    },
  },

  created() {
    this.seriesForChart();
  },

  setup() {
    // Destructure only the keys we want to use
    const { mdAndUp } = useDisplay();
    const { xsAndUp } = useDisplay();

    const { name } = useDisplay();

    const chart_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "200%";
        case "sm":
          return "200%";
        case "md":
          return (95 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "lg":
          return (160 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xl":
          return (169 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xxl":
          return (95 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
      }

      return undefined;
    });

    // eslint-disable-next-line vue/no-dupe-keys
    return { chart_height, mdAndUp, xsAndUp };
  },
};
</script>
