<script setup>
import { defineProps, computed } from "vue";
import { useStore } from "vuex";
import DictionaryForReport from "@/globals/DictionaryForReport";
import horizontalLinesImage from "@/assets/report-images/horizontal-lines.png";
import CandidateReportKPILegend from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReportKPILegend.vue";
import CandidateReportArchetypeAffinityTable from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReportArchetypeAffinityTable.vue";
import CandidateReportCultureProfileChart from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReportCultureProfileChart.vue";

// Definimos el prop con un objeto por defecto para asegurar la reactividad
const props = defineProps({
  reportData: {
    type: Object,
    default: () => ({
      name: "",
      mindsetFitIdealPercent: "N/D",
      archValuesChartSeries: [],
      archMax1: "",
      archMax1TextSummary: "",
      archMax2: "",
      archMax2TextSummary: "",
      valuesTitle: [],
    }),
  },
});

const store = useStore();

function calculateFitKPIColor(value) {
  if (value < 70) return "#F7E66B";
  if (value > 80) return "#8ABE66";
  if (value >= 70 && value <= 80) return "#CADFB8";
  return "#F7E66B";
}

function calculateFitKPIBackgroundColor(value) {
  if (value < 70) return "#FBF3BB";
  if (value > 80) return "#C9E1B9";
  if (value >= 70 && value <= 80) return "#E7F0DE";
  return "#FBF3BB";
}

const candidateOrgFitColor = computed(() => {
  return calculateFitKPIColor(props.reportData.mindsetFitIdealPercent);
});

const candidateOrgFitBgColor = computed(() => {
  return calculateFitKPIBackgroundColor(
    props.reportData.mindsetFitIdealPercent
  );
});

// Computed para evaluar si se debe renderizar la sección
const hasValidMindsetFit = computed(() => {
  const value = props.reportData.mindsetFitIdealPercent;
  // Si es numérico, asumimos que es válido
  if (typeof value === "number") return true;
  // Si es cadena, comprobamos que no sea 'N/D'
  if (typeof value === "string") return value !== "N/D";
  return false;
});

const ExecutiveSummary = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][9];
});
const MentOF = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][10];
});
const FirstMent = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][11];
});
const PredValueTitle = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][12];
});
const SecondMent = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][13];
});
const EngageOfText = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][14];
});
const EngageActual = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][15];
});
/* const IdealMent = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][124];
}); */
const footerText = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][3];
});
</script>

<template>
  <div id="executiveSummary" class="page">
    <div class="page-body">
      <img :src="horizontalLinesImage" class="page3-horizontal-lines" />

      <div class="main-container">
        <div>
          <div class="title">{{ ExecutiveSummary }}</div>
          <div class="subtitle mt-2">
            {{ MentOF }}<strong>{{ reportData.name }}</strong>
          </div>
        </div>

        <div class="charts">
          <div class="chart">
            <div style="margin-top: -20px">
              <CandidateReportCultureProfileChart
                width="240px"
                height="220px"
                :series="reportData.archValuesChartSeries"
              />
            </div>
          </div>
          <div class="chart">
            <CandidateReportArchetypeAffinityTable
              :reportData="reportData"
              height="175px"
            />
          </div>
        </div>

        <table class="arch-predominant">
          <tr>
            <td class="dark-green">
              {{ FirstMent }}
              <strong>{{ reportData.archMax1 }}</strong>
            </td>
            <td class="light-green-lt" style="min-width: 190px">
              {{ PredValueTitle }}
            </td>
          </tr>
          <tr>
            <td class="regular-cell">{{ reportData.archMax1TextSummary }}</td>
            <td rowspan="3" class="regular-cell">
              <ul>
                <li v-for="(value, key) in reportData.valuesTitle" :key="key">
                  {{ value }}
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td class="dark-green">
              {{ SecondMent }}
              <strong>{{ reportData.archMax2 }}</strong>
            </td>
          </tr>
          <tr>
            <td class="regular-cell">
              {{ reportData.archMax2TextSummary }}
            </td>
          </tr>
        </table>

        <!-- Se renderiza la sección si tiene un valor válido en mindsetFitIdealPercent -->
        <div v-if="hasValidMindsetFit">
          <div class="subtitle">
            {{ EngageOfText }} <strong>{{ reportData.name }}</strong> y
            <strong>{{ reportData.paramName }}</strong>
          </div>
          <div class="kpis-and-legend">
            <div class="kpi-card">
              <div class="line"></div>
              <div class="text" v-html="EngageActual"></div>
              <div class="kpi-value">
                <v-progress-circular
                  :rotate="90"
                  :size="80"
                  :width="6"
                  :model-value="reportData.mindsetFitIdealPercent"
                  :color="candidateOrgFitColor"
                  bg-color="transparent"
                >
                  <v-avatar size="68" :color="candidateOrgFitBgColor">
                    <div class="kpi-value-number">
                      {{ reportData.mindsetFitIdealPercent }}
                    </div>
                  </v-avatar>
                </v-progress-circular>
              </div>
            </div>
          </div>
          <CandidateReportKPILegend />
        </div>
      </div>
    </div>
    <div class="page-footer">
      {{ footerText }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page3-horizontal-lines {
  width: 312px;
  position: absolute;
  margin-top: 0px;
  right: 0px;
}

.charts {
  display: flex;
  gap: 110px;
  justify-content: space-around;
  .chart {
    min-width: 200px;
  }
}

.kpis-and-legend {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  .kpi-card {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    .line {
      width: 2px;
      background-color: #1f3863;
      height: 100%;
      transform: rotate(-12deg);
      margin-right: 12px;
    }
    .text {
      font-size: calc(9px * 1.333);
      color: #1f3863;
      > strong {
        font-weight: bold;
        color: #47b7a8;
      }
    }
    .kpi-value {
      max-width: 80px;
      width: 80px;
      height: 80px;
      .kpi-value-number {
        font-weight: bold;
        color: #1f3863;
        font-family: Poppins, sans-serif;
      }
    }
  }
}

table.arch-predominant {
  margin-top: -24px;
}

.v-progress-circular > svg {
  transform: rotate(0deg) !important;
}
</style>
