<template>
  <v-container fluid>
    <v-row>
      <v-text-field
        class="ml-9"
        v-model="IdealName"
        label="Nombre de la mentalidad ideal"
        type="text"
        :error="nameError"
        :error-messages="nameErrorMessage"
        required
      ></v-text-field>
    </v-row>
    <v-row>
      <p class="ml-9">
        Haz click en los valores que buscas en la persona(máximo 12), asigna un
        nombre al perfil y pulsa el botón <strong>"Grabar"</strong>
      </p>
    </v-row>

    <v-row>
      <v-col cols="12" lg="12">
        <v-row>
          <v-col
            cols="12"
            md="2"
            v-for="(block, index) in blocks"
            :key="`block-${index}`"
          >
            <v-card>
              <div
                class="drag-area"
                v-for="(item, idx) in block.items"
                :key="`item-${idx}-${index}`"
                @click="selectItem(item, block.header, idx)"
                :class="{ 'clickable-item': selectedValues.length < 12 }"
              >
                <div>
                  {{ Object.keys(item)[0] }}
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <div class="selected-area">
          <div
            class="drag-item"
            v-for="(item, index) in selectedValues"
            :key="`selected-${index}`"
          >
            <span>{{ Object.keys(item)[0] }}</span>
            <span class="remove-button" @click="removeItem(index)">×</span>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="6" lg="2">
        <v-btn class="btnParaGB" @click="go_back">
          <v-icon class="iconBtnPG">mdi mdi-keyboard-return</v-icon>
          Volver <br />a panel
        </v-btn>
      </v-col>
      <v-col cols="6" lg="2">
        <v-btn
          class="btnParaG"
          @click="checkBeforePosting"
          :disabled="selectedValues.length === 0"
        >
          Grabar
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="confirmDialogVisible" persistent max-width="500px">
      <v-card>
        <v-card-title>Confirmación</v-card-title>
        <v-card-text>
          ¿Estás seguro de que quieres grabar esta parametrización?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="confirmParametrization"
            >Confirmar</v-btn
          >
          <v-btn color="red darken-1" text @click="cancelParametrization"
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row dense class="mt-3 d-flex justify-center">
      <v-col cols="10" md="10">
        <v-dialog
          v-model="dialogVisible"
          width="50%"
          transition="dialog-top-transition"
        >
          <v-card
            class="d-flex justify-center text-center"
            color="#00C3C0"
            theme="dark"
          >
            <v-btn
              icon
              class="close-dialog"
              @click="dialogVisible = false"
              style="
                position: absolute;
                top: 2px;
                right: 2px;
                background-color: #506274;
              "
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-container>
              <h2 class="text-uppercase" style="color: white">
                <v-icon>mdi mdi-check-circle-outline</v-icon> Enhorabuena
              </h2>
              <v-row dense class="d-flex justify-center text-center">
                <v-col cols="11" md="10">
                  <div>
                    <p class="text-subtitle-1 mt-3" style="color: white">
                      Ya has parametrizado la mentalidad ideal en base a
                      valores.<br />
                    </p>
                  </div>
                </v-col>
              </v-row>
              <v-row dense class="d-flex justify-center text-center">
                <v-col cols="11" md="10">
                  <div>
                    <p class="text-subtitle-1 mt-3" style="color: white">
                      Dirígete a la tabla de perfiles y asígnale esta
                      parametrización a una persona en la columna
                      <strong>"comparar con"</strong>
                      <br />
                      Una vez lo hayas hecho, podrás hace click en el nombre de
                      la persona y tendrás los resultados en la pestaña
                      <br />
                      <strong>'Mindset Fit'</strong>
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProfileEvaluationService from "@/services/ProfileEvaluationService";
import { mapState } from "vuex";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      initialBlocks: [], // Para almacenar el estado inicial de blocks
      IdealName: "",
      nameError: false,
      nameErrorMessage: "",
      dialogVisible: false,
      confirmDialogVisible: false,
      selectedValues: [],
      blocks: [
        {
          header: "People",
          items: [
            { Cercanía: 0 },
            { Generosidad: 6 },
            { "Trabajo en equipo": 12 },
            { Lealtad: 18 },
            { Confianza: 24 },
            { Compromiso: 30 },
          ],
        },
        {
          header: "Innovation",
          items: [
            { Dinamismo: 1 },
            { Creatividad: 7 },
            { Autonomía: 13 },
            { Innovación: 19 },
            { "Tolerancia al riesgo": 25 },
            { Atrevimiento: 31 },
          ],
        },
        {
          header: "Goals",
          items: [
            { "Orientación a resultados": 2 },
            { Excelencia: 8 },
            { Competitividad: 14 },
            { Pragmatismo: 20 },
            { Ambición: 26 },
            { Liderazgo: 32 },
          ],
        },
        {
          header: "Norms",
          items: [
            { Formalidad: 3 },
            { Coordinación: 9 },
            { Estabilidad: 15 },
            { Responsabilidad: 21 },
            { Perseverancia: 27 },
            { Eficiencia: 33 },
          ],
        },
        {
          header: "Digital",
          items: [
            { "Apertura al cambio": 4 },
            { "Enfoque analítico": 10 },
            { Cooperación: 16 },
            { "Tolerancia al fallo": 22 },
            { "Orientación al aprendizaje": 28 },
            { "Adaptación al cliente": 34 },
          ],
        },
        {
          header: "ESG",
          items: [
            { Bienestar: 5 },
            { Integridad: 11 },
            { Transparencia: 17 },
            { Respeto: 23 },
            { Diversidad: 29 },
            { Sostenibilidad: 35 },
          ],
        },
      ],
      colorScale: [
        "#34495E",
        "#34495E",
        "#34495E",
        "#34495E",
        "#34495E",
        "#34495E",
      ],
      itemOrigins: {},
    };
  },

  computed: {
    ...mapState(["user_info", "orderedHeaders"]),
  },
  methods: {
    ...mapMutations([
      "parametrization_view_change_step",
      "parametrization_view_change_step_to_desktop",
      "setCurrentStep",
    ]),
    go_back() {
      this.resetValues();
      this.setCurrentStep(4); // Cambia la vista
    },
    resetValues() {
      // Restaurar blocks a su estado inicial
      this.blocks = JSON.parse(JSON.stringify(this.initialBlocks));
      // Limpiar valores seleccionados
      this.selectedValues = [];
      // Resetear nombre y errores
      this.IdealName = "";
      this.nameError = false;
      this.nameErrorMessage = "";
      // Resetear diálogos
      this.dialogVisible = false;
      this.confirmDialogVisible = false;
      // Limpiar orígenes de items
      this.itemOrigins = {};
    },
    selectItem(item, blockHeader, index) {
      // Verifica si ya se alcanzó el límite de 12 valores
      if (this.selectedValues.length >= 12) return;

      const itemKey = Object.keys(item)[0];

      if (
        this.selectedValues.some(
          (v) => JSON.stringify(v) === JSON.stringify(item)
        )
      )
        return;

      if (!this.itemOrigins[itemKey]) {
        this.itemOrigins[itemKey] = blockHeader;
      }

      this.selectedValues.push(item);

      const blockIndex = this.blocks.findIndex(
        (block) => block.header === blockHeader
      );
      if (blockIndex !== -1) {
        this.blocks[blockIndex].items.splice(index, 1);
      }
    },
    removeItem(index) {
      const removedItem = this.selectedValues[index];
      const itemKey = Object.keys(removedItem)[0];

      const originalBlockHeader = this.itemOrigins[itemKey];
      if (originalBlockHeader) {
        const blockIndex = this.blocks.findIndex(
          (block) => block.header === originalBlockHeader
        );
        if (blockIndex !== -1) {
          this.blocks[blockIndex].items.push(removedItem);
        }
      }

      this.selectedValues.splice(index, 1);
    },
    checkBeforePosting() {
      if (this.IdealName.trim() === "") {
        this.nameError = true;
        this.nameErrorMessage =
          "Por favor, ingresa un nombre para la mentalidad ideal.";
      } else {
        this.nameError = false;
        this.nameErrorMessage = "";
        this.confirmDialogVisible = true;
      }
    },
    confirmParametrization() {
      this.postScores();
      this.confirmDialogVisible = false;
    },
    cancelParametrization() {
      this.confirmDialogVisible = false;
    },
    compileScores() {
      return this.selectedValues.map((item) => Object.values(item)[0]);
    },
    async postScores() {
      const numerosSeleccionados = this.compileScores();

      try {
        const response =
          await ProfileEvaluationService.postParametrizeCandidate(
            this.IdealName,
            JSON.stringify(numerosSeleccionados),
            this.user_info.tokens.tokenPE
          );

        if (response && response.data && response.data.code === 200) {
          this.dialogVisible = true;
          this.parametrization_view_change_step_to_desktop();
          this.resetValues();
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  created() {
    // Guardar el estado inicial de los blocks cuando se crea el componente
    this.initialBlocks = JSON.parse(JSON.stringify(this.blocks));
  },
};
</script>

<style>
.drag-area {
  min-height: 1.025rem;
  width: 100%;
  background-color: #ffffff;
  cursor: pointer;
  border-top: 1px solid #f0f0f0;
  padding: 0.345rem;
  font-size: 1rem;
  text-align: center;
}
.clickable-item {
  transition: background 0.2s ease-in-out;
}

.selected-area {
  min-height: 100px;
  border: 2px dashed #00c3c0;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  background-color: #f9f9f9;
}
.drag-item {
  background: #00c3c0;
  color: white;
  padding: 8px 12px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  min-width: 100px;
  font-size: 16px;
  font-weight: bold;
}
.remove-button {
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  padding-left: 8px;
}
.headerTop {
  color: white;
}
.btnParaG {
  background-color: #34495e;
  color: white !important;
  font-size: 9px;
  padding-left: 50px;
  padding-right: 50px;
  justify-content: center;
  text-align: center;
}
.btnParaGB {
  background-color: #c22727;
  color: white !important;
  font-size: 9px;
  padding-left: 50px;
  padding-right: 50px;
  justify-content: center;
  text-align: center;
}
.iconBtnPG {
  font-size: 20px;
}
.close-dialog {
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 10;
}
.close-dialog .v-icon {
  color: white;
}
</style>
