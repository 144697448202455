<script setup>
import { useStore } from "vuex";

import DictionaryForReport from "@/globals/DictionaryForReport";

import "@fontsource/poppins/400.css";
import "@fontsource/poppins/600.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { computed, defineExpose, defineProps, reactive, ref } from "vue";

import "vue-loading-overlay/dist/css/index.css";

import CandidateReportCover from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReportCover.vue";
import ProfileEvaluationService from "@/services/ProfileEvaluationService";

import NoEnterpriseDataCandidateReportCover from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/NoEnterpriseDataCandidateReportCover.vue";

import CandidateReport6Cultures from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReport6Cultures.vue";
import CandidateReportExecutiveSummary from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReportExecutiveSummary.vue";

import NoEnterpriseDataCandidateReportExecutiveSummary from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/NoEnterpriseDataCandidateReportExecutiveSummary.vue";

import CandidateReportAppendix1 from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReportAppendix1.vue";
import CandidateReportAppendix2 from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReportAppendix2.vue";

import CandidateReportCurrentCulturalFit from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReportCurrentCulturalFit.vue";
import CandidateReportDesiredCulturalFit from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReportDesiredCulturalFit.vue";
import CandidateReportMindsetOf from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReportMindsetOf.vue";
import CandidateReportPredominantValues from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReportPredominantValues.vue";

import CandidateReportIdealFit from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReportIdealFit.vue";
import CandidateReport36Chart from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReport36Chart.vue";
import CandidateReportBack from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReportBack.vue";
import Utils from "@/globals/Utils";
import Values from "@/globals/Values";

const isLoading = ref(false);

const { list6AllZero, list36AllZero } = Values;

const props = defineProps({
  candidateMindsetFit: Array,
  userInfo: Object,
});

const {
  colorOfCandidateValues,
  colorCulturaVividaDiagnostico,
  colorOfDesireValues,
  colorCulturaParametrizada,
} = Values;

const store = useStore();
//txt Dictionary
const PersonMet = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][97];
});
const ActualCulture = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][98];
});
const DesiredCulture = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][99];
});
const IdealValues = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][122];
});
const PersonValues = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][123];
});
const IdealMindset = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][124];
});
const PersonMindset = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][125];
});
const CultureValues = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][128];
});
const DesiredValues = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][129];
});
const WIN_TOGETHER = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][133];
});
const Dynamism = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][112];
});
const Innovation = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][79];
});
const Outcome_orientation = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][85];
});
const Excellence = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][87];
});
const Stability = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][90];
});
const Efficiency = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][96];
});
const Cooperation = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][78];
});
const Customer_adaptation = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][120];
});
const Teamwork = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][77];
});
const Commitment = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][118];
});
const Responsibility = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][93];
});
const Well_being = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][113];
});

const MAKE_IT_HAPPEN = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][134];
});
const MAKE_IT_BETTER = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][135];
});
const MAKE_IT_COST_FOCUSED = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][136];
});
const MAKE_IT_CUSTOMER_ORIENTED = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][137];
});
const MAKE_IT_SAFE = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][138];
});
const Baja = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][139];
});
const Media = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][107];
});
const Alta = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][106];
});
const colorsForIdealChart = [colorCulturaParametrizada, colorOfCandidateValues];
const colorsForCultureChart = [
  colorCulturaVividaDiagnostico,
  colorOfCandidateValues,
];
const colorsForDesireChart = [colorOfDesireValues, colorOfCandidateValues];
const culturalComparative = "valores vividos";
const desireComparative = "valores deseados";

const reportData = reactive({
  name: "-",
  token: "-",
  email: "-",
  project: "-",
  archInd: [],
  fitCandOrg: "-",
  fitCandDOrg: "-",
  fitCandOrgEval: "-",
  fitCandDOrgEval: "-",
  fitCandOrgEvalText: "-",
  fitCandDOrgEvalText: "-",
  pAOrg: [],
  pADes: [],
  archTitle: [],
  archMax1: "-",
  archMax2: "-",
  archMax1Text: "-",
  archMax2Text: "-",
  archMax1TextSummary: "-",
  archMax2TextSummary: "-",
  archMax1Area: "-",
  archMax1AreaProcessed: [],
  archMax2Area: "-",
  archMax2AreaProcessed: [],
  archMin1Area: "-",
  archMin1AreaProcessed: [],
  archMin2Area: "-",
  archMin2AreaProcessed: [],
  valuesTitle: [],
  valuesText: [],
  valuesCompetences: [],
  integrityScore16: [],
  integrityIndicator: "-",
  integrityRecommendation: "-",
  integrityRecommendationText: "-",
  integrityValues: [],
  integrityValBehaviour: [],
  integrityProb: [],
  mindsetFitIdealPercent: "-",
  paramName: "-",
  TxtCuadrante: "-",
  tokenText: "",
  predominance: [],
  predominantValues: [],
  mostLikelyBehaviours: [],

  archValuesChartSeries: [
    {
      name: "Predominance Values",
      data: [10, 20, 30, 40, 50, 60],
    },
  ],

  candidateIntegritySeries: [
    {
      name: "Cultura de Integridad en la Persona",
      data: [1, 2, 3, 4, 5, 6],
    },
  ],

  KPITTransformacional: "0",
  KPITTransaccional: "0",
  series_33: [
    {
      name: "Liderazgo transformacional",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10],
    },
  ],
  series_44: [
    {
      name: "Liderazgo transaccional",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10],
    },
  ],
  series_6_IdealVsPersona: [
    {
      name: IdealMindset,
      data: [10, 20, 30, 40, 50, 60],
    },
    {
      name: PersonMindset,
      data: [10, 20, 30, 40, 50, 60],
    },
  ],
  series_6_CulturaVsPersona: [
    {
      name: ActualCulture,
      data: [10, 20, 30, 40, 50, 60],
    },
    {
      name: PersonMet,
      data: [10, 20, 30, 40, 50, 60],
    },
  ],
  series_6_DeseadaVsPersona: [
    {
      name: DesiredCulture,
      data: [10, 20, 30, 40, 50, 60],
    },
    {
      name: PersonMet,
      data: [10, 20, 30, 40, 50, 60],
    },
  ],
  series_36_IdealVsPersona: [
    {
      name: IdealValues,
      data: [
        10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60,
        10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60,
      ],
    },
    {
      name: PersonValues,
      data: [
        10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60,
        10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60,
      ],
    },
  ],
  series_36_CulturaVsPersona: [
    {
      name: CultureValues,
      data: [
        10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60,
        10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60,
      ],
    },
    {
      name: PersonValues,
      data: [
        10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60,
        10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60,
      ],
    },
  ],
  series_36_DeseadaVsPersona: [
    {
      name: DesiredValues,
      data: [
        10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60,
        10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60,
      ],
    },
    {
      name: PersonValues,
      data: [
        10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60,
        10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60, 10, 20, 30, 40, 50, 60,
      ],
    },
  ],

  opcionesReport: [true, true, true, true],
  //LLAMAR AL DIÁLOGO ,PARAMETRIZAR EL REPORTE(TRES OPCIONES TRUE FALSE ) Y GENERAR EL REPORTE
  //opcinesReport[0] = Informe de Ajuste con cultura coorporativa
  //opcionesReport[1] = Informe de Comportamiento Ético (anexo1)
  //opcionesReport[2] = Informe de Liderazgo (anexo2)
  //opcionesReport[3] = Informe  de comparación con el MINDSETFIT
});

function updateReportData(newOptions) {
  reportData.opcionesReport = newOptions;
}

function generateReport(projectToken, candidateToken) {
  isLoading.value = true;
  cleanReportData();
  requestReportData(projectToken, candidateToken);
}

function cleanReportData() {
  reportData.name = "-";
  reportData.token = "-";
  reportData.email = "-";
  reportData.project = "-";
  reportData.archInd = [];
  reportData.fitCandOrg = "-";
  reportData.fitCandDOrg = "-";
  reportData.fitCandOrgEval = "-";
  reportData.fitCandDOrgEval = "-";
  reportData.fitCandOrgEvalText = "-";
  reportData.fitCandDOrgEvalText = "-";
  reportData.pAOrg = [];
  reportData.pADes = [];
  reportData.archTitle = [];
  reportData.archMax1 = "-";
  reportData.archMax2 = "-";
  reportData.archMax1Text = "-";
  reportData.archMax2Text = "-";
  reportData.archMax1TextSummary = "-";
  reportData.archMax2TextSummary = "-";
  reportData.archMax1Area = "-";
  reportData.archMax1AreaProcessed = [];
  reportData.archMax2Area = "-";
  reportData.archMax2AreaProcessed = [];
  reportData.archMin1Area = "-";
  reportData.archMin1AreaProcessed = [];
  reportData.archMin2Area = "-";
  reportData.archMin2AreaProcessed = [];
  reportData.valuesTitle = [];
  reportData.valuesText = [];
  reportData.valuesCompetences = [];
  reportData.integrityScore16 = [];
  reportData.integrityIndicator = "-";
  reportData.integrityRecommendation = "-";
  reportData.integrityRecommendationText = "-";
  reportData.integrityValues = [];
  reportData.integrityValBehaviour = [];
  reportData.integrityProb = [];

  reportData.tokenText = "";
  reportData.predominance = [];
  reportData.archValuesChartSeries[0].data = [10, 20, 30, 40, 50, 60];
  reportData.predominantValues = [];
  reportData.candidateIntegritySeries[0].data = [1, 2, 3, 4, 5, 6];
  reportData.mostLikelyBehaviours = [];
  reportData.ParamBehaviours = [];
  reportData.KPITTransformacional = "0";
  reportData.KPITTransaccional = "0";
  reportData.series_33[0].data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  reportData.series_44[0].data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  reportData.series_6_IdealVsPersona[0].data = list6AllZero;
  reportData.series_6_IdealVsPersona[1].data = list6AllZero;
  reportData.series_6_CulturaVsPersona[0].data = list6AllZero;
  reportData.series_6_CulturaVsPersona[1].data = list6AllZero;
  reportData.series_6_DeseadaVsPersona[0].data = list6AllZero;
  reportData.series_6_DeseadaVsPersona[1].data = list6AllZero;
  reportData.series_36_IdealVsPersona[0].data = list36AllZero;
  reportData.series_36_IdealVsPersona[1].data = list36AllZero;
  reportData.series_36_CulturaVsPersona[0].data = list36AllZero;
  reportData.series_36_CulturaVsPersona[1].data = list36AllZero;
  reportData.series_36_DeseadaVsPersona[0].data = list36AllZero;
  reportData.series_36_DeseadaVsPersona[1].data = list36AllZero;
  reportData.mindsetFitIdealPercent = "-";
  reportData.paramName = "-";
  reportData.TxtCuadrante = "-";
}

async function requestReportData(projectToken, candidateToken) {
  try {
    const [candDataRes, candSurveyRes, avProjectSurveyRes] = await Promise.all([
      ProfileEvaluationService.getCandidateReportData(
        projectToken,
        candidateToken,
        store.getters.currentLanguage
      ),
      ProfileEvaluationService.getCandidateSurveyResult(candidateToken),
      ProfileEvaluationService.getAverageInnerSurvey(
        props.userInfo.tokens.tokenDC
      ),
    ]);

    let data = candDataRes.data;
    let candSurveyData = candSurveyRes.data;
    let avProjectSurveyData = avProjectSurveyRes.data;

    reportData.name = data.nombre;
    reportData.token = data.token;
    reportData.email = data.email || "";
    reportData.project = data.proyecto;
    reportData.archInd = data.cultura.ARQind;
    reportData.fitCandOrg =
      data.fitCandOrg === undefined || data.fitCandOrg === null
        ? "N/D"
        : data.fitCandOrg;

    reportData.fitCandDOrg = data.fitCandDOrg;
    reportData.fitCandOrgEval = data.fitCandOrgEval
      ? data.fitCandOrgEval
      : "BAJO";
    reportData.fitCandDOrgEval = data.fitCandDOrgEval
      ? data.fitCandDOrgEval
      : "BAJO";
    reportData.fitCandOrgEvalText = data.fitCandOrgEvalTEXTO;
    reportData.fitCandDOrgEvalText = data.fitCandDOrgEvalTEXTO;
    reportData.pAOrg = data.cultura.pAorg;
    reportData.pADes = data.cultura.pAdes;
    reportData.archTitle = data.arqTITULO;
    reportData.archMax1 = data.ARQmax1;
    reportData.archMax2 = data.ARQmax2;
    reportData.archMax1Text = data.ARQmax1texto;
    reportData.archMax2Text = data.ARQmax2texto;
    reportData.archMax1TextSummary = data.ARQmax1textoResumen;
    reportData.archMax2TextSummary = data.ARQmax2textoResumen;
    reportData.archMax1Area = data.ARQmax1area;
    reportData.archMax1AreaProcessed = convertToList(reportData.archMax1Area);
    reportData.archMax2Area = data.ARQmax2area;
    reportData.archMax2AreaProcessed = convertToList(reportData.archMax2Area);
    reportData.archMin1Area = data.ARQmin1area;
    reportData.archMin1AreaProcessed = convertToList(reportData.archMin1Area);
    reportData.archMin2Area = data.ARQmin2area;
    reportData.archMin2AreaProcessed = convertToList(reportData.archMin2Area);
    reportData.valuesTitle = data.valoresTITULO;
    reportData.valuesText = data.valoresTEXTO;
    reportData.valuesCompetences = data.valoresCOMPETENCIAS;
    reportData.integrityScore16 = data.IntegridadPuntuacion16;
    reportData.integrityIndicator = data.IntegridadIndicador;
    reportData.integrityRecommendation = data.IntegridadRecomendacion;
    reportData.integrityRecommendationText = data.IntegridadRecomendTEXTO;
    reportData.integrityValues = data.IntegridadValores;
    reportData.integrityValBehaviour = data.IntegridadValCOMPORTAMIENTOS;
    reportData.integrityProb = data.IntegridadProb;

    reportData.paramValues = data.ParametrizacionValores;
    reportData.paramValBehaviour = data.ParametrizacionComportamientos;
    reportData.paramProb = data.ParametrizacionProb;
    reportData.paramProbNum = data.ParametrizacionProb;

    reportData.tokenText = reportData.token.replace("CF-", "");
    reportData.predominance = processPredominance(data);
    reportData.archValuesChartSeries[0].data = data.cultura.ARQind;
    reportData.predominantValues = processPredominantValues(data);
    reportData.candidateIntegritySeries[0].data = data.IntegridadPuntuacion16;
    reportData.mostLikelyBehaviours = processMostLikelyBehaviours(data);
    reportData.ParamBehaviours = processParamBehaviours(data);
    reportData.KPITTransformacional = data.KPItransformacional;
    reportData.KPITTransaccional = data.KPItransaccional;
    reportData.series_33[0].data = data.transfo.LiderPuntuacion;
    reportData.series_44[0].data = data.transac.LiderPuntuacion;

    /*  data.cultura.VALind === undefined ? "N/D" : data.cultura.VALind; */

    reportData.series_6_IdealVsPersona[0].data =
      data.param === undefined ? [0, 0, 0, 0, 0, 0] : data.param.pApar;

    reportData.series_6_IdealVsPersona[1].data =
      data.param === undefined ? [0, 0, 0, 0, 0, 0] : data.cultura.ARQind;

    reportData.series_6_CulturaVsPersona[0].data = data.cultura.pAorg;
    reportData.series_6_CulturaVsPersona[1].data = data.cultura.ARQind;
    reportData.series_6_DeseadaVsPersona[0].data = data.cultura.pAdes;
    reportData.series_6_DeseadaVsPersona[1].data = data.cultura.ARQind;

    reportData.series_36_IdealVsPersona[0].data =
      data.param === undefined
        ? [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ]
        : data.param.mVpar;

    //VALind
    reportData.series_36_IdealVsPersona[1].data =
      data.cultura.VALind === undefined
        ? [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ]
        : data.cultura.VALind;
    reportData.series_36_CulturaVsPersona[0].data =
      avProjectSurveyData?.averageSurviesResults === undefined
        ? [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ]
        : Utils.transformaDataList(
            avProjectSurveyData.averageSurviesResults.cultureOrganizationBlock
          );

    //VALind
    reportData.series_36_CulturaVsPersona[1].data =
      candSurveyData?.survey === undefined
        ? [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ]
        : Utils.transformaDataList(candSurveyData.survey.personalBlock);
    reportData.series_36_DeseadaVsPersona[0].data =
      avProjectSurveyData?.averageSurviesResults === undefined
        ? [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ]
        : Utils.transformaDataList(
            avProjectSurveyData.averageSurviesResults.desireOrganizationBlock
          );

    //VALind
    reportData.series_36_DeseadaVsPersona[1].data =
      candSurveyData?.survey === undefined
        ? [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ]
        : Utils.transformaDataList(candSurveyData.survey.personalBlock);

    reportData.mindsetFitIdealPercent =
      data.param === undefined ? "N/D" : data.param.mindsetFit;

    reportData.paramName =
      data.proyectoParam === undefined ? "N/D" : data.proyectoParam;

    reportData.TxtCuadrante = data.textoCuadrante;

    generatePDF();
  } catch (error) {
    console.log(error);
    isLoading.value = false;
  }
}

function generatePDF() {
  setTimeout(function () {
    html2canvas(
      document.querySelector("#candidate-report, #candidate-report2"),
      {
        width: 794,
        windowWidth: 794,
        scale: 3,
        useCORS: true,
        allowTaint: false,
      }
    ).then(function (canvas) {
      const imgData = canvas.toDataURL("image/jpeg", 0.5);
      const doc = new jsPDF({
        orientation: "p",
        compress: true,
        unit: "px",
        format: "a4",
        putOnlyUsedFonts: true,
        hotfixes: ["px_scaling"],
      });

      var pheight = doc.internal.pageSize.getHeight();
      var pixelRatioCorrector = 1;
      var cwidth = (canvas.width / 3) * pixelRatioCorrector;
      var cheight = (canvas.height / 3) * pixelRatioCorrector;

      var numberPages = Math.round(cheight / pheight);

      for (let pageIndex = 0; pageIndex < numberPages; pageIndex++) {
        if (pageIndex > 0) doc.addPage();

        doc.addImage(
          imgData,
          "JPEG",
          0,
          pageIndex * -pheight,
          parseFloat(cwidth),
          parseFloat(cheight),
          "reportImage",
          "SLOW" // Calidad más alta
        );
      }

      doc.save(
        "Candidate_Report_" +
          reportData.name +
          "_" +
          new Date().toLocaleDateString() +
          ".pdf"
      );

      isLoading.value = false;
    });
  }, 1200);
}

function processPredominance(data) {
  let predominance = [];

  for (var index in data.arqTITULO) {
    predominance.push({
      title: data.arqTITULO[index],
      value: data.cultura.ARQind[index],
    });
  }

  predominance.sort(function (a, b) {
    return b.value - a.value;
  });

  return predominance;
}

function processPredominantValues(data) {
  let predominantValues = [];

  for (var index in data.valoresTITULO) {
    predominantValues.push({
      title: data.valoresTITULO[index],
      text: data.valoresTEXTO[index],
      competences: data.valoresCOMPETENCIAS[index],
    });
  }

  return predominantValues;
}

function processMostLikelyBehaviours(data) {
  let mostLikelyBehaviours = [];

  for (var index in data.IntegridadValores) {
    mostLikelyBehaviours.push({
      value: data.IntegridadValores[index],
      behaviour: data.IntegridadValCOMPORTAMIENTOS[index],
      probability: data.IntegridadProb[index],
    });
  }

  return mostLikelyBehaviours;
}

function processParamBehaviours(data) {
  if (
    !data ||
    !data.ParametrizacionValores ||
    !Array.isArray(data.ParametrizacionValores)
  ) {
    console.log("No parametrization data available, returning empty array");
    return []; // Return empty array if data is not available
  }

  // Verificar si es el proyecto Vueling usando userInfo
  const isVueling =
    props.userInfo?.tokens?.tokenPE === "CF495946056987" ||
    props.userInfo?.tokens?.tokenPE === "CF466958239108";
  //local CF466958239108
  //prod CF495946056987

  // Verificar si es el proyecto Veritas usando userInfo
  const isVeritas =
    props.userInfo?.tokens?.tokenPE === "9999999999999" ||
    props.userInfo?.tokens?.tokenPE === "9999999999999";
  //local CF132301683533
  //prod CF122798561545

  if (isVueling) {
    const vuelingMapping = {
      "WIN TOGETHER": {
        text: WIN_TOGETHER,
        match: [Teamwork.value, Commitment.value],
      },
      "MAKE IT HAPPEN": {
        text: MAKE_IT_HAPPEN,
        match: [Dynamism.value, Innovation.value],
      },
      "MAKE IT BETTER": {
        text: MAKE_IT_BETTER,
        match: [Outcome_orientation.value, Excellence.value],
      },
      "MAKE IT COST FOCUSED": {
        text: MAKE_IT_COST_FOCUSED,
        match: [Stability.value, Efficiency.value],
      },
      "MAKE IT CUSTOMER ORIENTED": {
        text: MAKE_IT_CUSTOMER_ORIENTED,
        match: [Cooperation.value, Customer_adaptation.value],
      },
      "MAKE IT SAFE": {
        text: MAKE_IT_SAFE,
        match: [Responsibility.value, Well_being.value],
      },
    };

    let ParamBehaviours = [];

    Object.entries(vuelingMapping).forEach(([vuelingValue, mappingData]) => {
      // Buscar los índices de los valores definidos en mappingData.match
      const indices = mappingData.match.map((value) =>
        data.ParametrizacionValores.indexOf(value)
      );

      // Obtener las probabilidades numéricas y calcular el promedio
      const validProbs = indices
        .filter((i) => i !== -1)
        .map((index) => data.ParametrizacionProbNum[index] || 0);

      const avgProb =
        validProbs.length > 0
          ? validProbs.reduce((sum, prob) => sum + prob, 0) / validProbs.length
          : 0;

      // Determinar la probabilidad en formato texto según el promedio
      let probText;
      if (avgProb <= 1) probText = Baja;
      else if (avgProb <= 2) probText = Media;
      else probText = Alta;

      ParamBehaviours.push({
        value: vuelingValue,
        // Se utiliza el comportamiento hardcodeado en lugar de extraerlo de los datos
        behaviour: mappingData.text,
        probability: probText,
        probNumber: avgProb.toFixed(2),
        projectType: "vueling", // Añadimos bandera para tipo de proyecto
      });
    });

    return ParamBehaviours;
  } else if (isVeritas) {
    // Mapeo para Veritas con equivalencias específicas
    const veritasMapping = {
      Liderazgo: {
        value: "Liderazgo",
        text: "Cambiamos el mundo desde el supermercado, siendo protagonistas y no espectadores. Tomamos decisiones, aun cuando nadie las ha tomado antes, para dar ejemplo y convencer a los demás para que se sumen a nuestro propósito. Como las abejas, polinizamos el mundo.",
      },
      Compromiso: {
        value: "Pasión",
        text: "Nuestro propósito nos levanta con fuerza cada mañana y da sentido a nuestras vidas. Estamos convencidos de las bondades y beneficios de lo ecológico y ponemos el máximo empeño en nuestro trabajo, disfrutando de cada momento.",
      },
      Integridad: {
        value: "Honestidad",
        text: "El resultado económico y el crecimiento son la consecuencia de seguir fielmente nuestro propósito, y no al revés. Somos de verdad, en nuestra propuesta de productos y servicios, y en nuestra forma de trabajar.",
      },
      Cercanía: {
        value: "Amabilidad",
        text: "Ser amable es ser digno de ser amado. Los pequeños gestos provocan los grandes cambios. Un mundo más amable es un mundo mejor, más justo y unido. Somos motores de amabilidad, con nosotros mismos y con los demás.",
      },
      Sostenibilidad: {
        value: "Impacto",
        text: "Tenemos vocación transformadora. Queremos dejar el mundo mejor de lo que estaba antes de nosotros. Nuestros clientes deben mejorar su salud a través de nuestra alimentación y del conocimiento que les trasladamos. Nuestros proveedores deben estar mejor después de colaborar con nosotros. El planeta y el ecosistema deben respirar mejor después de nuestra actividad.",
      },
    };

    // Crear ParamBehaviours para Veritas
    const ParamBehaviours = [];

    // Iterar por los valores originales
    data.ParametrizacionValores.forEach((valor, index) => {
      if (veritasMapping[valor]) {
        const mappingData = veritasMapping[valor];

        ParamBehaviours.push({
          value: mappingData.value, // Valor Veritas equivalente
          behaviour: mappingData.text, // Comportamiento hardcodeado de Veritas
          probability: data.ParametrizacionProb[index], // Mantener la probabilidad original
          probNumber: data.ParametrizacionProbNum[index], // Mantener el valor numérico original
          projectType: "veritas", // Añadimos bandera para tipo de proyecto
        });
      }
    });

    return ParamBehaviours;
  }

  // Caso normal (no-Vueling, no-Veritas)
  return data.ParametrizacionValores.map((valor, index) => ({
    value: valor,
    behaviour: data.ParametrizacionComportamientos[index],
    probability: data.ParametrizacionProb[index],
    probNumber: data.ParametrizacionProbNum[index],
    projectType: "default", // Añadimos bandera para tipo de proyecto
  }));
}
/* function processParamBehaviours(data) {
  if (
    !data ||
    !data.ParametrizacionValores ||
    !Array.isArray(data.ParametrizacionValores)
  ) {
    console.log("No parametrization data available, returning empty array");
    return []; // Return empty array if data is not available
  }

  // Verificar si es el proyecto Vueling usando userInfo
  const isVueling =
    props.userInfo?.tokens?.tokenPE === "CF495946056987" ||
    props.userInfo?.tokens?.tokenPE === "CF466958239108";
  // Verificar si es el proyecto Veritas usando userInfo
  const isVeritas =
    props.userInfo?.tokens?.tokenPE === "9999999999999" ||
    props.userInfo?.tokens?.tokenPE === "9999999999999";

  if (isVueling) {
    console.log(
      "=========================== Procesando mapeo Vueling ================================="
    );
    console.log(
      "Valores entrantes (ParametrizacionValores):",
      data.ParametrizacionValores
    );

    // Imprimir los valores esperados (del diccionario) para cada grupo
    console.log("Valores del diccionario para Vueling:");
    console.log("WIN TOGETHER:", WIN_TOGETHER.value);
    console.log("MAKE IT HAPPEN:", MAKE_IT_HAPPEN.value);
    console.log("MAKE IT BETTER:", MAKE_IT_BETTER.value);
    console.log("MAKE IT COST FOCUSED:", MAKE_IT_COST_FOCUSED.value);
    console.log("MAKE IT CUSTOMER ORIENTED:", MAKE_IT_CUSTOMER_ORIENTED.value);
    console.log("MAKE IT SAFE:", MAKE_IT_SAFE.value);
    console.log("Teamwork:", Teamwork.value);
    console.log("Commitment:", Commitment.value);
    console.log("Dynamism:", Dynamism.value);
    console.log("Innovation:", Innovation.value);
    console.log("Outcome_orientation:", Outcome_orientation.value);
    console.log("Excellence:", Excellence.value);
    console.log("Stability:", Stability.value);
    console.log("Efficiency:", Efficiency.value);
    console.log("Cooperation:", Cooperation.value);
    console.log("Customer_adaptation:", Customer_adaptation.value);
    console.log("Responsibility:", Responsibility.value);
    console.log("Well_being:", Well_being.value);

    const vuelingMapping = {
      "WIN TOGETHER": {
        text: WIN_TOGETHER.value,
        match: [Teamwork.value, Commitment.value],
      },
      "MAKE IT HAPPEN": {
        text: MAKE_IT_HAPPEN.value,
        match: [Dynamism.value, Innovation.value],
      },
      "MAKE IT BETTER": {
        text: MAKE_IT_BETTER.value,
        match: [Outcome_orientation.value, Excellence.value],
      },
      "MAKE IT COST FOCUSED": {
        text: MAKE_IT_COST_FOCUSED.value,
        match: [Stability.value, Efficiency.value],
      },
      "MAKE IT CUSTOMER ORIENTED": {
        text: MAKE_IT_CUSTOMER_ORIENTED.value,
        match: [Cooperation.value, Customer_adaptation.value],
      },
      "MAKE IT SAFE": {
        text: MAKE_IT_SAFE.value,
        match: [Responsibility.value, Well_being.value],
      },
    };

    let ParamBehaviours = [];

    Object.entries(vuelingMapping).forEach(([vuelingValue, mappingData]) => {
      console.log(`\nProcesando mapeo para: ${vuelingValue}`);
      // Mostrar los valores esperados a buscar (accediendo a .value)
      const expectedMatchValues = mappingData.match.map((m) => m.value);
      console.log(
        `Valores esperados para ${vuelingValue}:`,
        expectedMatchValues
      );

      // Buscar los índices de los valores definidos en mappingData.match
      const indices = mappingData.match.map((value) => {
        const indexFound = data.ParametrizacionValores.indexOf(value);
        console.log(
          `Buscando valor "${value.value}" en ParametrizacionValores, índice encontrado: ${indexFound}`
        );
        return indexFound;
      });

      // Obtener las probabilidades numéricas y calcular el promedio
      const validProbs = indices
        .filter((i) => i !== -1)
        .map((index) => {
          const prob = data.ParametrizacionProbNum[index] || 0;
          console.log(`Índice ${index} tiene probabilidad: ${prob}`);
          return prob;
        });

      const avgProb =
        validProbs.length > 0
          ? validProbs.reduce((sum, prob) => sum + prob, 0) / validProbs.length
          : 0;
      console.log(`Probabilidad promedio para "${vuelingValue}": ${avgProb}`);

      // Determinar la probabilidad en formato texto según el promedio
      let probText;
      if (avgProb <= 1) probText = "Baja";
      else if (avgProb <= 2) probText = "Media";
      else probText = "Alta";
      console.log(`Probabilidad (texto) para "${vuelingValue}": ${probText}`);

      ParamBehaviours.push({
        value: vuelingValue,
        behaviour: mappingData.text,
        probability: probText,
        probNumber: avgProb.toFixed(2),
        projectType: "vueling", // Añadimos bandera para tipo de proyecto
      });
    });

    return ParamBehaviours;
  } else if (isVeritas) {
    // Mapeo para Veritas (sin logs de Vueling)
    const veritasMapping = {
      Liderazgo: {
        value: "Liderazgo",
        text: "Cambiamos el mundo desde el supermercado, siendo protagonistas y no espectadores. Tomamos decisiones, aun cuando nadie las ha tomado antes, para dar ejemplo y convencer a los demás para que se sumen a nuestro propósito. Como las abejas, polinizamos el mundo.",
      },
      Compromiso: {
        value: "Pasión",
        text: "Nuestro propósito nos levanta con fuerza cada mañana y da sentido a nuestras vidas. Estamos convencidos de las bondades y beneficios de lo ecológico y ponemos el máximo empeño en nuestro trabajo, disfrutando de cada momento.",
      },
      Integridad: {
        value: "Honestidad",
        text: "El resultado económico y el crecimiento son la consecuencia de seguir fielmente nuestro propósito, y no al revés. Somos de verdad, en nuestra propuesta de productos y servicios, y en nuestra forma de trabajar.",
      },
      Cercanía: {
        value: "Amabilidad",
        text: "Ser amable es ser digno de ser amado. Los pequeños gestos provocan los grandes cambios. Un mundo más amable es un mundo mejor, más justo y unido. Somos motores de amabilidad, con nosotros mismos y con los demás.",
      },
      Sostenibilidad: {
        value: "Impacto",
        text: "Tenemos vocación transformadora. Queremos dejar el mundo mejor de lo que estaba antes de nosotros. Nuestros clientes deben mejorar su salud a través de nuestra alimentación y del conocimiento que les trasladamos. Nuestros proveedores deben estar mejor después de colaborar con nosotros. El planeta y el ecosistema deben respirar mejor después de nuestra actividad.",
      },
    };

    const ParamBehaviours = [];

    data.ParametrizacionValores.forEach((valor, index) => {
      if (veritasMapping[valor]) {
        const mappingData = veritasMapping[valor];

        ParamBehaviours.push({
          value: mappingData.value, // Valor Veritas equivalente
          behaviour: mappingData.text, // Comportamiento hardcodeado de Veritas
          probability: data.ParametrizacionProb[index], // Mantener la probabilidad original
          probNumber: data.ParametrizacionProbNum[index], // Mantener el valor numérico original
          projectType: "veritas", // Añadimos bandera para tipo de proyecto
        });
      }
    });

    return ParamBehaviours;
  }

  // Caso normal (no-Vueling, no-Veritas)
  return data.ParametrizacionValores.map((valor, index) => ({
    value: valor,
    behaviour: data.ParametrizacionComportamientos[index],
    probability: data.ParametrizacionProb[index],
    probNumber: data.ParametrizacionProbNum[index],
    projectType: "default", // Añadimos bandera para tipo de proyecto
  }));
} */

function convertToList(text) {
  return text.split(",").map(function (item) {
    return item.trim();
  });
}

defineExpose({
  generateReport,
  updateReportData,
});
</script>
<template>
  <div class="custom-loading-overlay" v-if="isLoading">
    <div class="custom-spinner" style="width: 84px; height: 84px">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
        <path
          d="M50 10L85 30v40L50 90L15 70V30L50 10z"
          fill="none"
          stroke="#00c3c0"
          stroke-width="4"
          stroke-linecap="round"
          stroke-dasharray="250"
        />
      </svg>
    </div>
    <p style="font-weight: bold; margin-top: 16px; color: #00c3c0">
      Generando PDF...
    </p>
  </div>
  <div id="candidate-report" class="report-tpl-container">
    <CandidateReportCover
      v-bind:reportData="reportData"
      v-if="reportData.opcionesReport[0]"
    />
    <NoEnterpriseDataCandidateReportCover
      v-bind:reportData="reportData"
      v-else
    />
    <CandidateReport6Cultures />
    <CandidateReportExecutiveSummary
      v-bind:reportData="reportData"
      v-if="reportData.opcionesReport[0] && reportData.fitCandOrg !== 'N/D'"
    />
    <NoEnterpriseDataCandidateReportExecutiveSummary
      v-bind:reportData="reportData"
      v-else
    />
    <CandidateReportMindsetOf
      v-bind:reportData="reportData"
      v-bind:candidateMindsetFit="props.candidateMindsetFit"
    />
    <CandidateReportPredominantValues v-bind:reportData="reportData" />

    <CandidateReportIdealFit
      v-bind:reportData="reportData"
      v-bind:chartColors="colorsForIdealChart"
      v-if="
        reportData.opcionesReport[3] &&
        reportData.mindsetFitIdealPercent !== 'N/D'
      "
    />

    <CandidateReportCurrentCulturalFit
      v-bind:reportData="reportData"
      v-bind:chartColors="colorsForCultureChart"
      v-if="reportData.opcionesReport[0] && reportData.fitCandOrg !== 'N/D'"
    />
    <CandidateReport36Chart
      v-bind:report36Data="reportData.series_36_CulturaVsPersona"
      v-bind:name="reportData.name"
      v-bind:comparative="culturalComparative"
      v-bind:enterprise="reportData.project"
      v-bind:chartColors="colorsForCultureChart"
      v-if="reportData.opcionesReport[0] && reportData.fitCandOrg !== 'N/D'"
    />

    <CandidateReportDesiredCulturalFit
      v-bind:reportData="reportData"
      v-bind:chartColors="colorsForDesireChart"
      v-if="reportData.opcionesReport[0] && reportData.fitCandOrg !== 'N/D'"
    />
    <CandidateReport36Chart
      v-bind:report36Data="reportData.series_36_DeseadaVsPersona"
      v-bind:name="reportData.name"
      v-bind:comparative="desireComparative"
      v-bind:enterprise="reportData.project"
      v-bind:chartColors="colorsForDesireChart"
      v-if="reportData.opcionesReport[0] && reportData.fitCandOrg !== 'N/D'"
    />

    <CandidateReportAppendix2
      v-bind:reportData="reportData"
      v-if="reportData.opcionesReport[2]"
    />
    <CandidateReportAppendix1
      v-bind:reportData="reportData"
      v-if="reportData.opcionesReport[1]"
    />

    <CandidateReportBack />
  </div>

  <!-- <div
    id="candidate-report2"
    class="report-tpl-container"
    v-if="reportData.pAOrg == null"
  >
    <NoEnterpriseDataCandidateReportCover v-bind:reportData="reportData" />
    <CandidateReport6Cultures />
    <NoEnterpriseDataCandidateReportExecutiveSummary
      v-bind:reportData="reportData"
    />
    <CandidateReportMindsetOf v-bind:reportData="reportData" />
    <CandidateReportPredominantValues v-bind:reportData="reportData" />
    <CandidateReportAppendix1 v-bind:reportData="reportData" />
    <CandidateReportBack />
  </div>

  <div id="candidate-report" class="report-tpl-container" v-else>
    <CandidateReportCover v-bind:reportData="reportData" />
    <CandidateReport6Cultures />
    <CandidateReportExecutiveSummary v-bind:reportData="reportData" />
    <CandidateReportMindsetOf v-bind:reportData="reportData" />
    <CandidateReportPredominantValues v-bind:reportData="reportData" />
    <CandidateReportCurrentCulturalFit v-bind:reportData="reportData" />
    <CandidateReportDesiredCulturalFit v-bind:reportData="reportData" />
    <CandidateReportAppendix1 v-bind:reportData="reportData" />
    <CandidateReportBack />
  </div> -->
</template>

<style lang="scss">
@import "@/assets/scss/candidate-report.scss";
@import "@/assets/scss/candidate-report2.scss";
.custom-loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.custom-spinner {
  animation: spin 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.custom-spinner svg path {
  animation: dash 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

@keyframes dash {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 250;
  }
}
</style>
